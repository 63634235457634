import React from 'react';
import './Header.css';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Store from './../../services/Store.js';

class Header extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {};
	}

	changeLanguage(code){
		if(Store.retrieveLanguage() !== code){
            Store.updateLanguage(code);
            const { i18n } = this.props;
            i18n.changeLanguage(code);
        }
	}

	render(){
		return (
			<div className="wrapper">
			    <div className="footer-bar border-grad">
			      <div className="container">
			        <div className="d-flex justify-content-between navigation-smart">
			          <div className="d-flex justify-content-start navigation-main-logo-cnt">
			          	<a href="#" className="navigation-senec-logo">Company name</a>
			            <a href="#" className="navigation-main-logo">Company name</a>
			          </div>
			          <div className="col navigation-links">
			            <div className="d-flex justify-content-end px-0">
			              <div className="navigation-smart-links-container"></div>
			              <div className="d-flex justify-content-end">
			                <ul className="navigation-smart-links d-block">
			                  <li className={"navigation-smart-link cursor-pointer"+(Store.retrieveLanguage() === 'en' ? " active" : "")} onClick={()=>this.changeLanguage('en')}>
			                    EN
			                  </li>
			                  <li className={"navigation-smart-link ml-2 ml-lg-3 cursor-pointer"+(Store.retrieveLanguage() === 'fr' ? " active" : "")} onClick={()=>this.changeLanguage('fr')}>
			                    FR
			                  </li>
			                  <li className={"navigation-smart-link ml-2 ml-lg-3 cursor-pointer"+(Store.retrieveLanguage() === 'nl' ? " active" : "")} onClick={()=>this.changeLanguage('nl')}>
			                    NL
			                  </li>
			                  <li className="underline bg-blue"></li>
			                </ul>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			</div>		
		);
	}

}
export default withRouter(withTranslation()(Header));